
<script lang="ts">
    export interface IProps {
        register?: any;
        href?: string;
        header: string;
        count: string | number;
    }
</script>

<template>
    <div class="bg-white border" style="padding: 0.75rem; border-radius: 0.5rem;">
        <!-- header -->
        <div class="d-flex">
            <a class="flex-grow-1" :href="href">
                <div class="fw-bold">
                    <div>{{ header }} ({{ count }})</div>
                </div>
            </a>
            <div class="flex-shrink-0 px-2">
                <OButton variant="link" color="black" icon="bi-chevron-down" :iconRotation="show ? 180 : 0" @click="show = !show" />
            </div>
        </div>

        <!-- content -->
        <div>
            <OCollapse v-model="show">
                <div class="mt-2">
                    <slot />
                </div>
            </OCollapse>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from "vue";
    import { OCollapse } from "o365-ui-components";

    const props = defineProps<IProps>();

    const show = ref<boolean>(false);
</script>
